import React from "react";

export function Step4() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Les fonctionnalités`}
            </h2>
            <p>{`Les fonctionnalités sont sur cette page :`}</p>
            <img
                src={`images/help/feature.png`}
                style={{border: "1px solid #4777", width: "100%"}}
            />

            <p style={{marginTop: "16px"}}>{`Vous pouvez activer ou désactiver :`}</p>
            <ul>
                <li>{`les e-mails de rappels`}</li>
                <li>
                    {`les e-mails de notification :`}
                    <ul>
                        <li>{`à la création d'un RDV`}</li>
                        <li>{`au changement de date`}</li>
                    </ul>
                </li>
                <li>{`la synchronisation avec Google Agenda`}</li>
            </ul>
        </>
    );
}
