import {logout} from "app/reducers/user/authentication";
import React, {useLayoutEffect} from "react";
import {useDispatch} from "react-redux";
import {Loading} from "@mytour/ui/Loading";

const LogoutPage = () => {
    const dispatch = useDispatch();
    useLayoutEffect(() => {
        dispatch(logout());
        window.location.href = `${window.location.origin}`;
    }, []);

    return <Loading />;
};
export default LogoutPage;
