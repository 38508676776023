import {Buffer} from "buffer";
import {Features, IPlatformUser} from "../types";

const DEFAULT_TAX_RAT = 0.2;
const DEFAULT_FEATURES: Features = {
    notifAtSave: false,
    notifDayBefore: false,
    syncAgenda: true,
    agendaDisplay: true,
};

function getFeatures(platformUser: IPlatformUser): Features {
    const features: any = platformUser.features;
    if (features?.type === "Buffer") {
        const bufferString = Buffer.from(features?.data).toString("utf8");
        return {...DEFAULT_FEATURES, ...JSON.parse(bufferString)};
    } else if (features) {
        return {...DEFAULT_FEATURES, ...JSON.parse(features)};
    } else {
        return {...DEFAULT_FEATURES};
    }
}

function getTaxRate(platformUser: IPlatformUser): number {
    let result = 0;
    if (platformUser.vatId) {
        result = DEFAULT_TAX_RAT;
    }
    return result;
}

export const featureService = {
    getFeatures,
    getTaxRate,
};
