// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {Suspense} from "react";
import {Switch} from "react-router-dom";
import {Loading} from "@mytour/ui/Loading";
import ErrorBoundaryRoute from "ui-kit/error/ErrorBoundaryRoute";
import {HomePage} from "./HomePage";
const SupportPage = React.lazy(() => import("./account/support/SupportPage"));
const PasswordResetInit = React.lazy(
    () => import("./account/password-reset/PasswordResetInitPage")
);
const Password = React.lazy(() => import("./account/password/PasswordPage"));
const InvoiceSettings = React.lazy(() => import("./account/settings/InvoiceSettingsPage"));
const Settings = React.lazy(() => import("./account/settings/AccountSettingsPage"));
const Features = React.lazy(() => import("./account/settings/FeaturesPage"));
const Event = React.lazy(() => import("./event"));
const Customer = React.lazy(() => import("./customer"));
const ServiceDescription = React.lazy(() => import("./service-description"));
const Invoice = React.lazy(() => import("./invoice"));
const Performances = React.lazy(() => import("./reports"));
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({match}) => (
    // @ts-ignore - mono-repo
    <Switch>
        {/* prettier-ignore */}
        <Suspense fallback={<Loading />}>
      <ErrorBoundaryRoute path={`${match.url}home`} component={HomePage} />
      <ErrorBoundaryRoute path={`${match.url}customer`} component={Customer as any /*mono-repo*/} />

      <ErrorBoundaryRoute path={`${match.url}service-description`} component={ServiceDescription as any /*mono-repo*/} />

      <ErrorBoundaryRoute path={`${match.url}event`} component={Event as any /*mono-repo*/} />

      <ErrorBoundaryRoute path={`${match.url}invoice`} component={Invoice as any /*mono-repo*/} />
      <ErrorBoundaryRoute path={`${match.url}reports`} component={Performances as any /*mono-repo*/} />

      <ErrorBoundaryRoute path={`${match.url}account/support`} component={SupportPage as any /*mono-repo*/} />
      <ErrorBoundaryRoute path={`${match.url}account/reset/request`} component={PasswordResetInit as any /*mono-repo*/} />
      <ErrorBoundaryRoute path={`${match.url}account/reset/finish/:key?`} component={PasswordResetInit as any /*mono-repo*/} />
      <ErrorBoundaryRoute path={`${match.url}account/settings`} component={Settings as any /*mono-repo*/} />
      <ErrorBoundaryRoute path={`${match.url}account/features`} component={Features as any /*mono-repo*/} />
      <ErrorBoundaryRoute path={`${match.url}account/invoice-settings`} component={InvoiceSettings as any /*mono-repo*/} />
      <ErrorBoundaryRoute path={`${match.url}account/password`} component={Password as any /*mono-repo*/} />
    </Suspense>
    </Switch>
);

export default Routes;
