import React, {useState} from "react";

import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {useDispatch} from "react-redux";
import {Button, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Brand} from "ui-kit/layout/header/Brand";

import {saveAccountSettings} from "app/reducers/user/settings.reducer";
import {syncExec} from "app/services/dispatch.service";
import {IAccount} from "common/types";
import {ModalContent, ModalContentWrapper} from "../onboarding/ModalContent";
import {LetsGo} from "ui-kit/form/button/LetsGo";

export const CGU = 1;

type Props = {
    account: IAccount;
    cguUpdated: boolean;
};

export function AcceptCGUModal({cguUpdated, account}: Props) {
    const dispatch = useDispatch();

    const [welcome, setWelcome] = useState<boolean>(!cguUpdated);

    const [checked, setChecked] = useState<boolean>(false);
    const [checked2, setChecked2] = useState<boolean>(false);

    if (welcome) {
        return (
            <Modal
                isOpen={true}
                backdrop="static"
                id="end-of-trial-page"
                autoFocus={false}
                centered
            >
                <ModalHeader id="login-title" className="mytour-header" close={<></>}>
                    <Brand />
                </ModalHeader>
                <ModalBody>
                    <ModalContentWrapper>
                        <ModalContent>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    height: "100%",
                                }}
                            >
                                <h2 style={{marginTop: "8px"}} className="title-custom-style">
                                    {`Première connexion`}
                                </h2>
                                <p>{`Vous êtes maintenant connecté sur MyTour !`}</p>
                            </div>
                        </ModalContent>
                        <LetsGo onClick={() => setWelcome(false)} disabled={false} />
                    </ModalContentWrapper>
                </ModalBody>
            </Modal>
        );
    }

    const subtitle = cguUpdated
        ? `Mise à jour de nos conditions d'utilisation`
        : `Merci de consulter nos conditions d'utilisation`;
    const description = cguUpdated
        ? `Merci d'en prendre connaissance et de les accepter.`
        : `En utilisant MyTour, vous acceptez les conditions générales d'utilisation et la politique des données personnelles`;

    const disabled = !checked || !checked2;

    return (
        <Modal isOpen={true} backdrop="static" id="end-of-trial-page" autoFocus={false} centered>
            <ModalHeader id="login-title" className="mytour-header" close={<></>}>
                <Brand />
            </ModalHeader>
            <ModalBody>
                <ModalContentWrapper>
                    <ModalContent>
                        <p>
                            <b>{subtitle}</b>
                        </p>
                        <p>{description}</p>

                        <p>
                            <br />
                            <br />
                            {`J’ai lu et j’accepte :`}
                        </p>
                        <AvForm id="settings-form" onValidSubmit={handleValidSubmit}>
                            <AvGroup check>
                                <Label htmlFor="platform-user-syncAgenda">
                                    <a
                                        href="https://mytour.tech/cgu"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        les conditions générales d’utilisation
                                    </a>
                                </Label>
                                <AvInput
                                    id="platform-user-syncAgenda"
                                    type="checkbox"
                                    className="form-check-input"
                                    name="syncAgenda"
                                    value={checked}
                                    onChange={() => setChecked((prev) => !prev)}
                                />
                            </AvGroup>
                            <AvGroup check>
                                <Label htmlFor="platform-user-syncAgenda">
                                    <a
                                        href="https://mytour.tech/politique"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        la politique de respect des données personnelles
                                    </a>
                                </Label>
                                <AvInput
                                    id="platform-user-syncAgenda"
                                    type="checkbox"
                                    className="form-check-input"
                                    name="syncAgenda"
                                    value={checked}
                                    onChange={() => setChecked2((prev) => !prev)}
                                />
                            </AvGroup>
                        </AvForm>
                    </ModalContent>
                    <Button color="primary" type="submit" form="settings-form" disabled={disabled}>
                        Valider
                    </Button>
                </ModalContentWrapper>
            </ModalBody>
        </Modal>
    );

    function handleValidSubmit() {
        if (checked && account) {
            const newAccount = {...account, cgu: CGU};
            syncExec(dispatch(saveAccountSettings(newAccount)));
        }
    }
}
