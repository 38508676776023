import React from "react";

export function Step12() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Un rendez-vous`}
            </h2>
            <img
                src={`images/help/rdv-brouillon.png`}
                style={{border: "1px solid #4777", width: "100%"}}
            />
            <p style={{marginTop: "16px"}}>
                {`Une fois sauvergardé, le rendez-vous est à l'état de `}
                <b>{`brouillon`}</b>
                {`.`}
            </p>

            <p style={{marginTop: "16px"}}>
                <b>{`Les frais de déplacement sont automatiquement calculés.`}</b>
            </p>
            <p style={{marginTop: "16px"}}>
                {`Il est ensuite possible de le passer à l'état de `}
                <b>{`devis`}</b>
                {` puis de `}
                <b>{`facture`}</b>
                {` en un clic.`}
            </p>
        </>
    );
}
