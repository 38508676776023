import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {App} from "app/App";
import {clearAuthentication, loadSession} from "app/reducers/user/authentication";
import {Buffer} from "buffer";
import setupAxiosInterceptors from "config/axios-interceptor";
import "config/i18n";
import {loadIcons} from "config/icon-loader";
import initStore from "config/store";
import React from "react";
import {createRoot} from "react-dom/client";
import ReactGA from "react-ga4";
import {Provider} from "react-redux";
import {bindActionCreators} from "redux";

import i18next from "i18next";
import {z} from "zod";
import {zodI18nMap} from "zod-i18n-map";
// Import your language translation files
import translationMytour from "locales/fr/translation.json";
import translation from "locales/fr/zod.json";

// lng and resources key depend on your locale.
i18next.init({
    lng: "fr",
    resources: {
        fr: {zod: translation, translation: translationMytour},
    },
});
z.setErrorMap(zodI18nMap);

Buffer.name; // avoid import cleanup

let environment = "dev";
if (
    window.location.host === "latest.mytour-services.tech" ||
    window.location.host === "latest.mytour.tech"
) {
    environment = "latest";
} else if (
    window.location.host === "canary.mytour-services.tech" ||
    window.location.host === "canary.mytour.tech"
) {
    environment = "canary";
} else if (
    window.location.host === "mytour-services.tech" ||
    window.location.host === "mytour.tech" ||
    window.location.host === "app.mytour.tech"
) {
    environment = "production";
}
const TRACKING_ID = "G-MJ7JW5Y6CV";
ReactGA.initialize(TRACKING_ID);
Sentry.init({
    dsn: "https://89c1ebce7fc943b8a497fe7a33ee6a9c@o1191243.ingest.sentry.io/6313996",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment,
});

const store = initStore();

const actions = bindActionCreators({clearAuthentication}, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication("login.error.unauthorized"));
store.dispatch(loadSession() as any);

loadIcons();

const rootEl = document.getElementById("root");
if (rootEl) {
    const render = (Component) =>
        // eslint-disable-next-line react/no-render-return-value
        createRoot(rootEl).render(
            <Sentry.ErrorBoundary>
                {/* @ts-ignore - mono-repo */}
                <Provider store={store}>
                    <Component />
                </Provider>
            </Sentry.ErrorBoundary>
        );

    render(App);
}
