import React from "react";

export function Step3() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Les facture`}
            </h2>
            <p>{`Utilisez l'icône facture :`}</p>
            <img
                src={`images/help/facture.png`}
                style={{border: "1px solid #4777", width: "100%"}}
            />
            <p style={{marginTop: "16px"}}>
                {`Il est possible de consulter la facture à partir du rendez-vous.`}
                <br />
                {` Cependant, vous pouvez avoir besoin de chercher une facture à partir d'une autre information comme le `}
                <b>{`numéro de facture`}</b>
                {`.`}
            </p>
            <div className={`alert alert-info`} style={{marginTop: "8px"}}>
                <p>
                    {`Un outil d'export vous permet de télécharger vos activités.`}
                    <br />
                    <strong>{`Idéal pour la déclaration d'activité URSSAF.`}</strong>
                </p>
            </div>
        </>
    );
}
