import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {SplitterButtons} from "@mytour/ui/layout";
import {ACTION_TYPES} from "../../../../reducers/user/onboarding";
import {BackButton} from "ui-kit/form/button/BackButton";
import {LetsGo} from "ui-kit/form/button/LetsGo";
import {NextButton} from "ui-kit/form/button/NextButton";
import {NoButton} from "ui-kit/form/button/NoButton";
import {Step0} from "./components/Step0";
import {Step1} from "./components/Step1";
import {Step2} from "./components/Step2";
import {Step3} from "./components/Step3";
import {Step4} from "./components/Step4";
import {Step5} from "./components/Step5";
import {Step6} from "./components/Step6";
import {ModalContent, ModalContentWrapper} from "../../ModalContent";
import {Step11} from "./components/Step11";
import {Step12} from "./components/Step12";

type AStep = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export function HelpTour() {
    const dispatch = useDispatch();
    const [pending, setPending] = useState(false);
    const [step, setStep] = useState<AStep>(0);

    const firstStep = 0;
    const lastStep = 8;

    return (
        <ModalContentWrapper>
            <ModalContent>
                {step === firstStep && <Step0 />}
                {step === 1 && <Step1 />}
                {step === 2 && <Step11 />}
                {step === 3 && <Step12 />}
                {step === 4 && <Step2 />}
                {step === 5 && <Step3 />}
                {step === 6 && <Step4 />}
                {step === 7 && <Step5 />}
                {step === 8 && <Step6 />}
            </ModalContent>
            {step === firstStep ? (
                <SplitterButtons>
                    <NoButton onClick={handleFinished} disabled={pending} pending={pending} />
                    <LetsGo onClick={handleToNextStep} disabled={pending} />
                </SplitterButtons>
            ) : (
                <SplitterButtons>
                    <BackButton onClick={handleToPrevStep} disabled={pending} />
                    <NextButton
                        onClick={step === lastStep ? handleFinished : handleToNextStep}
                        disabled={pending}
                        pending={pending}
                    />
                </SplitterButtons>
            )}
        </ModalContentWrapper>
    );

    function handleToPrevStep() {
        const prevStep = Math.max(0, step - 1);
        setStep(prevStep as AStep);
    }

    function handleToNextStep() {
        setPending(true);
        try {
            const nextStep = step + 1;
            if (nextStep <= lastStep) {
                setStep(nextStep as AStep);
            }
        } finally {
            setPending(false);
        }
    }

    function handleFinished() {
        dispatch({
            type: ACTION_TYPES.SET_STATUS,
            status: "ENDING",
        });
    }
}
