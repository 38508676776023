import React from "react";

export function Step1() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Les rendez-vous`}
            </h2>
            <p>{`Utilisez l'icône agenda :`}</p>
            <img src={`images/help/rdv.png`} style={{border: "1px solid #4777", width: "100%"}} />
            <p style={{marginTop: "16px"}}>
                {`L'agenda vous permet de visualiser les rendez-vous et d'afficher en évidence les `}
                <b>{`impayés`}</b>
                {`.`}
            </p>
            <div className={`alert alert-info`}>
                <p>{`Un bouton "+ Ajouter" vous permet de créer un nouveau rendez-vous dans le jour sélectionné`}</p>
            </div>
        </>
    );
}
