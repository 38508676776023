import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import React, {useState} from "react";
import {Trans as Translate, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Splitter} from "@mytour/ui/layout";
import {Brand} from "ui-kit/layout/header/Brand";
import {Loading} from "@mytour/ui/Loading";
import {IRootState} from "../../../reducers";
import {saveAccountSettings} from "../../../reducers/user/settings.reducer";
import {syncExec} from "../../../services/dispatch.service";

import {updateEntity} from "app/reducers/user/platformUser.reducer";
export function PaymentDataModal({
    onAbort,
    onValidate,
}: {
    onAbort: () => void;
    onValidate: () => void;
}) {
    const dispatch = useDispatch();
    const {account, platformUser, updating, loading} = useSelector(
        ({authentication, settings, platformUser}: IRootState) => ({
            account: authentication.account,
            platformUser: platformUser.entity,

            loading: platformUser.loading || authentication.loading,
            updating: platformUser.updating || settings.updating,
        })
    );

    const {t: translate} = useTranslation();
    const [dirty, setDirty] = useState<boolean>(false);

    return (
        <Modal isOpen={true} backdrop="static" id="end-of-trial-page" autoFocus={false} centered>
            <ModalHeader id="login-title" close={<></>} className="mytour-header">
                <Brand />
                {`vérification de vos données`}
            </ModalHeader>
            <ModalBody>
                <p>
                    <b>{`Merci de vérifier vos informations`}</b>
                </p>
                {loading ? (
                    <Loading />
                ) : (
                    <AvForm
                        id="settings-form"
                        onValidSubmit={handleValidSubmit}
                        style={{display: "flex", flexDirection: "column", flexGrow: 1}}
                    >
                        <div style={{flexGrow: 1}}>
                            <AvGroup>
                                <AvField
                                    className="form-control"
                                    name="company"
                                    label={translate("myTourApp.platformUser.company") + " *"}
                                    id="platform-user-company"
                                    onChange={turnDirty}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: translate(
                                                "settings.messages.validate.required"
                                            ),
                                        },
                                    }}
                                    value={platformUser?.company}
                                    disabled={updating}
                                />
                            </AvGroup>

                            <AvGroup>
                                <AvField
                                    className="form-control"
                                    name="address"
                                    label={translate("myTourApp.platformUser.address") + " *"}
                                    id="platform-user-address"
                                    onChange={turnDirty}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: translate(
                                                "settings.messages.validate.required"
                                            ),
                                        },
                                    }}
                                    value={platformUser?.address}
                                    disabled={updating}
                                />
                            </AvGroup>
                            <AvGroup>
                                <AvField
                                    className="form-control"
                                    name="address2"
                                    onChange={turnDirty}
                                    id="platform-user-address2"
                                    value={platformUser?.address2}
                                    disabled={updating}
                                />
                            </AvGroup>

                            <Splitter>
                                <AvGroup>
                                    <AvField
                                        className="form-control"
                                        name="zip"
                                        label={translate("myTourApp.platformUser.zip") + " *"}
                                        id="platform-user-zip"
                                        onChange={turnDirty}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: translate(
                                                    "settings.messages.validate.required"
                                                ),
                                            },
                                        }}
                                        value={platformUser?.zip}
                                        disabled={updating}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <AvField
                                        className="form-control"
                                        name="city"
                                        label={translate("myTourApp.platformUser.city") + " *"}
                                        id="platform-user-city"
                                        onChange={turnDirty}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: translate(
                                                    "settings.messages.validate.required"
                                                ),
                                            },
                                        }}
                                        value={platformUser?.city}
                                        disabled={updating}
                                    />
                                </AvGroup>
                            </Splitter>

                            <AvGroup>
                                <AvField
                                    className="form-control"
                                    name="phone"
                                    label={translate("myTourApp.platformUser.phone")}
                                    id="platform-user-phone"
                                    onChange={turnDirty}
                                    value={platformUser?.phone ?? ""}
                                    disabled={updating}
                                />
                            </AvGroup>

                            <AvGroup>
                                {/* Email */}
                                <AvField
                                    name="email"
                                    label={translate("global.form.email.label") + " *"}
                                    placeholder={translate("global.form.email.placeholder")}
                                    type="email"
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: translate(
                                                "global.messages.validate.email.required"
                                            ),
                                        },
                                        minLength: {
                                            value: 5,
                                            errorMessage: translate(
                                                "global.messages.validate.email.minlength"
                                            ),
                                        },
                                        maxLength: {
                                            value: 254,
                                            errorMessage: translate(
                                                "global.messages.validate.email.maxlength"
                                            ),
                                        },
                                    }}
                                    onChange={turnDirty}
                                    value={account?.email ?? ""}
                                    disabled={updating}
                                />
                            </AvGroup>
                        </div>
                        {dirty && (
                            <Button color="primary" type="submit" disabled={updating}>
                                <Translate i18nKey="settings.form.button">Save</Translate>
                            </Button>
                        )}
                    </AvForm>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onAbort} id="abort" disabled={updating}>
                    Retour
                </Button>
                <Button
                    onClick={onValidate}
                    type="submit"
                    color="primary"
                    style={{float: "right"}}
                    disabled={dirty || updating}
                >
                    {`Valider`}
                </Button>
            </ModalFooter>
        </Modal>
    );

    async function handleValidSubmit(event, values) {
        if (account === null) {
            return;
        }
        for (const [key, value] of Object.entries(values)) {
            if (key in account) {
                account[key] = value;
            }
        }
        await syncExec(dispatch(saveAccountSettings(account)));

        if (platformUser) {
            const entity = {
                id: platformUser?.id,
            };
            for (const [key, value] of Object.entries(values)) {
                if (key in platformUser) {
                    entity[key] = value;
                }
            }

            await syncExec(dispatch(updateEntity(entity)));
        }
        setDirty(false);
    }

    function turnDirty() {
        setDirty(true);
    }
}
