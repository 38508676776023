import {IRootState} from "app/reducers";
import {onboarding} from "app/reducers/user/authentication";
import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {Button} from "reactstrap";
import {Loading} from "@mytour/ui/Loading";
import {Tracking} from "./Tracking";

export function OnboardingPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const {invitationKey} = useParams<{invitationKey: string}>();

    const invitationSuccess = useSelector(
        ({authentication}: IRootState) => authentication.invitationSuccess
    );
    const invitationError = useSelector(
        ({authentication}: IRootState) => authentication.invitationError
    );
    const errorMessage = useSelector(
        ({authentication}: IRootState) => authentication.errorMessage
    );

    useEffect(() => {
        if (invitationKey) {
            ReactGA.event("login", {method: "onboarding"});
            dispatch(onboarding(invitationKey));
        }
    }, []);
    if (invitationSuccess) {
        const {from} = (location.state as any) || {from: {pathname: "/", search: location.search}};
        // @ts-ignore - mono-repo
        return <Redirect to={from} />;
    } else if (invitationError) {
        return (
            <>
                <Tracking />
                <div
                    style={{
                        height: "100%",
                        display: "grid",
                        gridTemplateRows: "1fr min-content 1fr",
                    }}
                >
                    <div />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <h2>{"Erreur pendant la connexion automatique"}</h2>
                        <p>{`Merci d'utiliser vos identifiants de connexion`}</p>
                        <div>
                            <Button color="success" onClick={handleRedirectLogin}>
                                Se connecter
                            </Button>
                        </div>
                    </div>
                    <div />
                </div>
            </>
        );
    }
    return (
        <>
            <Tracking />
            <Loading />
        </>
    );

    function handleRedirectLogin() {
        history.push(`/login`);
    }
}
