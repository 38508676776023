import Pages from "app/pages";
import GoogleAuthPage from "app/pages/login/GoogleAuthPage";
import InvitationPage from "app/pages/login/InvitationPage";
import LoginPage from "app/pages/login/LoginPage";
import LogoutPage from "app/pages/login/LogoutPage";
import {IRootState} from "app/reducers";
import {AUTHORITIES} from "config/constants";
import React from "react";
import {useSelector} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from "ui-kit/auth/PrivateRoute";
import ErrorBoundaryRoute from "ui-kit/error/ErrorBoundaryRoute";
import PageNotFound from "ui-kit/error/PageNotFound";
import {Modals} from "./Modals";
import {OnboardingPage} from "./pages/login/OnboardingPage";

const HomePage = () => {
    const {authentication} = useSelector(({authentication}: IRootState) => ({
        authentication,
    }));
    const {account} = authentication;
    // @ts-ignore - mono-repo
    return account && account.login ? <Redirect to={"/home"} /> : <Redirect to={"/login"} />;
};
const Routes = () => {
    return (
        <>
            <div className="view-routes">
                {/* @ts-ignore - mono-repo */}
                <Switch>
                    {/* @ts-ignore - mono-repo */}
                    <Route path="/invitation/:invitationKey" component={InvitationPage} />
                    {/* @ts-ignore - mono-repo */}
                    <Route path="/onboarding/:invitationKey" component={OnboardingPage} />
                    <ErrorBoundaryRoute path="/login" component={LoginPage} />
                    <ErrorBoundaryRoute path="/google/auth" component={GoogleAuthPage} />
                    <ErrorBoundaryRoute path="/logout" component={LogoutPage} />
                    <ErrorBoundaryRoute path="/" exact component={HomePage} />

                    <PrivateRoute
                        path="/"
                        component={Pages}
                        hasAnyAuthorities={[AUTHORITIES.USER]}
                    />

                    {/* @ts-ignore - mono-repo */}
                    <ErrorBoundaryRoute component={PageNotFound} />
                </Switch>
            </div>

            <Modals />
        </>
    );
};

export default Routes;
