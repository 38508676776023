import * as Sentry from "@sentry/react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import "app/App.scss";
import "config/dayjs.ts";
import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Header} from "ui-kit/layout/header/Header";
import {SentryContainer} from "./SentryContainer";
import AppRoutes from "./routes";
const baseHref = document?.querySelector("base")?.getAttribute("href")?.replace(/\/$/, "");

const queryClient = new QueryClient();

export const App = () => {
    return (
        <Sentry.ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <ToastContainer
                    position={toast.POSITION.TOP_LEFT}
                    theme="colored"
                    className="toastify-container"
                    toastClassName="toastify-toast"
                />
                {/* @ts-ignore - mono-repo */}
                <Router basename={baseHref}>
                    <Header />
                    <AppRoutes />
                </Router>
                <SentryContainer />
            </QueryClientProvider>
        </Sentry.ErrorBoundary>
    );
};
