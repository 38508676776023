import {IRootState} from "app/reducers";
import React from "react";
import {useSelector} from "react-redux";
import {NavLink as Link} from "react-router-dom";
import {NavbarBrand} from "reactstrap";
import {Logo} from "./Logo";

export function Brand() {
    const isModeration = useSelector(
        ({authentication}: IRootState) => authentication.isModeration
    );

    return (
        <NavbarBrand tag={Link as any /* mono-repo */} to="/" className="brand-logo">
            <Logo />
            {isModeration && (
                <span
                    className="navbar-version"
                    style={{
                        position: "absolute",
                        color: "red",
                        zIndex: 2000,
                        marginLeft: "95px",
                        top: "36px",
                    }}
                >
                    Modération
                </span>
            )}
        </NavbarBrand>
    );
}
