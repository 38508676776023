import {LoginModal} from "app/features/login/LoginModal";
import {IRootState} from "app/reducers";
import React from "react";
import {useSelector} from "react-redux";
import {Redirect, useLocation} from "react-router-dom";

const Login = () => {
    const location = useLocation();
    const {isAuthenticated} = useSelector(({authentication}: IRootState) => ({
        isAuthenticated: authentication.isAuthenticated,
    }));

    const {from} = (location.state as any) || {from: {pathname: "/", search: location.search}};
    if (isAuthenticated) {
        // @ts-ignore - mono-repo
        return <Redirect to={from} />;
    } else {
        return <LoginModal />;
    }
};

export default Login;
