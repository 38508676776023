import {library} from "@fortawesome/fontawesome-svg-core";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";

import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import {faAsterisk} from "@fortawesome/free-solid-svg-icons/faAsterisk";
import {faBan} from "@fortawesome/free-solid-svg-icons/faBan";
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone";
import {faSms} from "@fortawesome/free-solid-svg-icons/faSms";
import {faBell} from "@fortawesome/free-solid-svg-icons/faBell";
import {faBook} from "@fortawesome/free-solid-svg-icons/faBook";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import {faCheckSquare} from "@fortawesome/free-solid-svg-icons/faCheckSquare";
import {faSquare} from "@fortawesome/free-solid-svg-icons/faSquare";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {faCloud} from "@fortawesome/free-solid-svg-icons/faCloud";
import {faCalendar} from "@fortawesome/free-solid-svg-icons/faCalendar";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";
import {faDollarSign} from "@fortawesome/free-solid-svg-icons/faDollarSign";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons/faFileCsv";
import {faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons/faFileInvoiceDollar";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons/faFilePdf";
import {faFlag} from "@fortawesome/free-solid-svg-icons/faFlag";
import {faHdd} from "@fortawesome/free-solid-svg-icons/faHdd";
import {faHeart} from "@fortawesome/free-solid-svg-icons/faHeart";
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import {faHorse} from "@fortawesome/free-solid-svg-icons/faHorse";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";
import {faMoneyBillTrendUp} from "@fortawesome/free-solid-svg-icons/faMoneyBillTrendUp";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons/faArrowTrendUp";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faRoad} from "@fortawesome/free-solid-svg-icons/faRoad";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import {faSort} from "@fortawesome/free-solid-svg-icons/faSort";
import {faSync} from "@fortawesome/free-solid-svg-icons/faSync";
import {faSyringe} from "@fortawesome/free-solid-svg-icons/faSyringe";
import {faTachometerAlt} from "@fortawesome/free-solid-svg-icons/faTachometerAlt";
import {faTasks} from "@fortawesome/free-solid-svg-icons/faTasks";
import {faThList} from "@fortawesome/free-solid-svg-icons/faThList";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons/faUserCircle";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons/faUserPlus";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {faWrench} from "@fortawesome/free-solid-svg-icons/faWrench";

export const loadIcons = () => {
    library.add(
        faCheckCircle,
        faPhone,
        faSms,
        faCheckSquare,
        faSquare,
        faMoneyBillTrendUp,
        faCircleInfo,
        faExclamationCircle,
        faArrowTrendUp,
        faCopy,
        faSort,
        faEye,
        faFileCsv,
        faCalendar,
        faSync,
        faBan,
        faTrash,
        faArrowLeft,
        faArrowRight,
        faSave,
        faPlus,
        faPencilAlt,
        faUser,
        faTachometerAlt,
        faHeart,
        faList,
        faTasks,
        faBook,
        faBan,
        faHdd,
        faLock,
        faSignInAlt,
        faSignOutAlt,
        faWrench,
        faThList,
        faUserPlus,
        faAsterisk,
        faFlag,
        faBell,
        faHome,
        faCalendarAlt,
        faUsers,
        faHorse,
        faSyringe,
        faFilePdf,
        faUserCircle,
        faGoogle,
        faCog,
        faCheck,
        faTimes,
        faDollarSign,
        faCircleNotch,
        faEnvelope,
        faClipboardList,
        faFileInvoiceDollar,
        faRoad,
        faCloud,
        faTimesCircle,
        faSearch
    );
};
