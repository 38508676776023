import React from "react";

export function Step2() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Les clients`}
            </h2>
            <p>{`Utilisez l'icône suivant pour voir les clients :`}</p>
            <img
                src={`images/help/client.png`}
                style={{border: "1px solid #4777", width: "100%"}}
            />
            <p style={{marginTop: "16px"}}>
                {`Il est possible d'ajouter les clients à partir du formulaire de rendez-vous.`}
                <br />
                {`Cependant, si vous avez besoin d'une mise à jour, cette page reste bien utile.`}
            </p>
            <div className={`alert alert-info`} style={{marginTop: "8px"}}>
                <p>
                    {`Si l'adresse du client n'est pas vérifiable, le client apparaîtra en rouge !`}
                </p>
            </div>
        </>
    );
}
