import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {NavLink as Link} from "react-router-dom";
import {OnboardingProgress} from "../features/onboarding/OnboardingProgress";

export function HomePage() {
    return (
        <div style={{height: "100%", display: "grid", gridTemplateRows: "1fr min-content"}}>
            <div className="home-menu">
                {/* @ts-ignore - mono-repo */}
                <Link to="/event" className="home-menu-item first">
                    <FontAwesomeIcon icon="calendar-alt" />
                    <div>Rendez-vous</div>
                </Link>
                {/* @ts-ignore - mono-repo */}
                <Link to="/customer" className="home-menu-item second">
                    <FontAwesomeIcon icon="users" />
                    <div>Client</div>
                </Link>
                {/* @ts-ignore - mono-repo */}
                <Link to="/invoice" className="home-menu-item third">
                    <FontAwesomeIcon icon="file-invoice-dollar" />
                    <div>Factures</div>
                </Link>
                {/* @ts-ignore - mono-repo */}
                <Link to="/service-description" className="home-menu-item fourth">
                    <FontAwesomeIcon icon="clipboard-list" />
                    <div>Prestations</div>
                </Link>
                {/* @ts-ignore - mono-repo */}
                <Link to="/account/invoice-settings" className="home-menu-item fifth">
                    <FontAwesomeIcon icon="wrench" />
                    <div>Configuration</div>
                </Link>
                {/* @ts-ignore - mono-repo */}
                <Link to="/reports" className="home-menu-item sixth">
                    <FontAwesomeIcon icon="arrow-trend-up" />
                    <div>Synthèse</div>
                </Link>
            </div>
            <OnboardingProgress />
        </div>
    );
}
