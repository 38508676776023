import React from "react";

export function Step6() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Les prestations`}
            </h2>
            <p>{`Les prestations sont sur cette page :`}</p>
            <img
                src={`images/help/prestation.png`}
                style={{border: "1px solid #4777", width: "100%"}}
            />
            <p style={{marginTop: "16px"}}>
                {`Il est possible d'ajouter une prestation à partir du formulaire de rendez-vous.`}
                {` Cependant, si vous avez besoin d'une mise à jour, cette page reste bien utile.`}
            </p>
        </>
    );
}
