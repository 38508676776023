import {zodResolver} from "@hookform/resolvers/zod";
import {updateEntity} from "app/reducers/user/platformUser.reducer";
import {featureService} from "common/services/featureService";
import {IAccount, IPlatformUser} from "common/types";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Trans as Translate} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "reactstrap";
import {z} from "zod";
import {SplitterButtons} from "@mytour/ui/layout";
import {ACTION_TYPES} from "../../../../reducers/user/onboarding";
import {syncExec} from "../../../../services/dispatch.service";
import {NoButton} from "ui-kit/form/button/NoButton";
import {YesButton} from "ui-kit/form/button/YesButton";
import {Step0} from "./components/Step0";
import {Step1} from "./components/Step1";
import {IRootState} from "../../../../reducers";
import {ModalContent, ModalContentWrapper} from "../../ModalContent";

type Props = {account: IAccount; platformUser: IPlatformUser};

const schemaGoogleAgenda = z.object({
    syncAgenda: z.boolean(),
});
type FormGoogleAgenda = z.infer<typeof schemaGoogleAgenda>;

export function AccountFeaturesGoogleAgenda({account, platformUser}: Props) {
    const [pending, setPending] = useState(false);
    const [step, setStep] = useState<0 | 1>(0);
    const dispatch = useDispatch();

    const hasGoogleCalendar = useSelector(({authentication}: IRootState) => {
        return authentication.account?.userinfo && authentication.account?.hasGoogleCalendar;
    });

    const {notifAtSave, notifDayBefore, syncAgenda} = featureService.getFeatures(platformUser);

    const form = useForm<FormGoogleAgenda>({
        resolver: zodResolver(schemaGoogleAgenda),
        defaultValues: {syncAgenda},
    });

    const Link = (props2) => <a href={props2.to} {...props2} />;
    return (
        <ModalContentWrapper>
            <ModalContent>
                <h2 style={{marginTop: "8px"}} className="title-custom-style">
                    {`Agenda`}
                </h2>
                {step === 0 && <Step0 />}
                {step === 1 && <Step1 account={account} />}
            </ModalContent>
            {step === 0 && (
                <>
                    <SplitterButtons>
                        <NoButton
                            onClick={() => {
                                form.setValue("syncAgenda", false);
                                handleSubmit();
                            }}
                            disabled={pending}
                            pending={pending}
                        />
                        <YesButton
                            onClick={() => {
                                form.setValue("syncAgenda", true);
                                handleSubmit();
                            }}
                            disabled={pending}
                            pending={pending}
                        />
                    </SplitterButtons>
                </>
            )}
            {step === 1 && (
                <SplitterButtons>
                    <NoButton onClick={handleAbort} disabled={pending} pending={pending} />
                    <Button
                        tag={Link}
                        color="success"
                        to={account?.authUrl}
                        style={{float: "right"}}
                        disabled={pending}
                    >
                        <Translate i18nKey="google.button">Login</Translate>
                    </Button>
                </SplitterButtons>
            )}
        </ModalContentWrapper>
    );

    async function handleSubmit() {
        setPending(true);
        try {
            const {syncAgenda} = form.getValues();
            const features = {notifAtSave, notifDayBefore, syncAgenda};
            const entity = {
                id: platformUser?.id,
                features: JSON.stringify(features),
            };
            await syncExec(dispatch(updateEntity(entity)));
            if (features.syncAgenda && !hasGoogleCalendar) {
                setStep(1);
            } else {
                dispatch({
                    type: ACTION_TYPES.SET_STATUS,
                    status: "FEATURES2",
                });
            }
        } finally {
            setPending(false);
        }
    }

    function handleAbort() {
        form.setValue("syncAgenda", false);
        handleSubmit();
    }
}
