import * as Sentry from "@sentry/react";
import React from "react";
import {Route, RouteProps} from "react-router-dom";

export const ErrorBoundaryRoute = ({component: Component, ...rest}: RouteProps) => {
    const encloseInErrorBoundary = (props) => (
        // @ts-ignore
        <Sentry.ErrorBoundary>{Component && <Component {...props} />}</Sentry.ErrorBoundary>
    );

    if (!Component)
        throw new Error(`A component needs to be specified for path ${(rest as any).path}`);
    // @ts-ignore
    return <Route {...rest} render={encloseInErrorBoundary} />;
};

export default ErrorBoundaryRoute;
