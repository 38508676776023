import RevolutCheckout from "@revolut/checkout";
import React, {useEffect} from "react";
import {toast} from "react-toastify";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Brand} from "ui-kit/layout/header/Brand";

import axios from "axios";
import {Loading} from "@mytour/ui/Loading";
export function RevolutModal({
    onAbort,
    onPay,
    type,
}: {
    onAbort: () => void;
    onPay: () => void;
    type: "monthly" | "yearly";
}) {
    useEffect(() => {
        pay();
    }, []);

    return (
        <Modal isOpen={true} backdrop="static" id="end-of-trial-page" autoFocus={false} centered>
            <ModalHeader id="login-title" close={<></>} className="mytour-header">
                <Brand />
                {`Paiement`}
            </ModalHeader>
            <ModalBody>
                <p style={{textAlign: "center"}}>
                    <b>{`Mise en place du formulaire de paiement`}</b>
                </p>
                <Loading />
            </ModalBody>
        </Modal>
    );

    async function pay() {
        const response = await axios.post("/api/order/", {type});

        const {publicId, mode, savePaymentMethodFor, options} = response.data;
        const RC = await RevolutCheckout(publicId, mode);

        RC.payWithPopup({
            ...options,
            savePaymentMethodFor,
            onSuccess() {
                axios.post(`/api/order/${publicId}/payment/`).then(() => {
                    onPay();
                });
            },
            onError() {
                toast.error("Erreur pendant le paiement");
                onAbort();
            },
            onCancel() {
                onAbort();
            },
        });
    }
}
