import React from "react";

export function Step11() {
    return (
        <>
            <h2 style={{marginTop: "8px", marginBottom: "16px"}} className="title-custom-style">
                {`Un rendez-vous`}
            </h2>
            <p>{`Pour créer un rendez-vous il faut préciser le client et la date :`}</p>
            <img
                src={`images/help/rdv-new.png`}
                style={{border: "1px solid #4777", width: "100%"}}
            />
            <p style={{marginTop: "16px"}}>
                {`Nous créons ici un rendez-vous avec `}
                <b>{`John Dupont`}</b> {`le `} <b>{`23/12/2024`}</b> {`à `} <b>{`14h`}</b>
                {`.`}
            </p>
        </>
    );
}
